import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../../templates/LayoutService'
import { blogHeader, blogPostContainer, postContentBlock } from '../../styles/components/blog.module.scss'
import { pageContentMedia } from '../../styles/components/blocks.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../../styles/components/boxes.module.scss'
import '../../styles/components/content.module.scss'
import ServicesList from '../../components/blocks/ServicesList'
import { Link } from 'gatsby'

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>What Do I Need to Bring to a Party Boat Trip in Tampa? | Tampa Bay Boating Adventures</title>
      </Helmet>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Hot girl and guy holding up drinks in Tampa Bay, FL."
                  src="../../images/blog/hot-girl-and-guy-holding-up-drinks-in-tampa-bay.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className='row center'>
                <div className='col'>
                  <h1>What Do I Need to Bring to a Party Boat Trip in Tampa?</h1>
                  <p>August 30, 2022</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xxs-12'>
                  <div className={postContentBlock}>
                    <p>So, you and your five friends have booked a party boat trip with us and are excited to get out on the water. It is important to know what to bring with you, and what is already provided so that your day runs smoothly. To help, we’ve created this handy guide for smooth sailing. Let’s take a closer look:</p>
                    <h2>What Is Included On Our Party Boat Charter:</h2>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="Friends toasting drinks at a charter boat party in Tampa Bay, FL."
                          src="../../images/blog/friends-toasting-drinks-at-a-charter-boat-party.jpg"
                        />
                      </div>
                    </div>
                    <h3>Professional Charter Boat Captain to Drive the Boat</h3>
                    <p>First and foremost, we provide a highly experienced captain to drive the boat for you. This means you can sit back, relax and enjoy your party boat trip without worrying about where you are heading or how to drive the boat (thank goodness!). Our captains are ready to get the party started and are still young, so no crusty old captains on this boat.</p>
                    <h3>Dry Snacks</h3>
                    <p>We provide a range of dry snacks that you can eat at your leisure throughout your cruise. This includes chips, Goldfish, Cheez-its, etc.</p>
                    <h3>Extra Sunscreen</h3>
                    <p>When you are out on the water, the sun’s rays can feel stronger as they are reflecting off the surface. We understand that the Florida sun can be hot at the best of times, and to help our guests out, we ensure that we have extra sunscreen on the boat just in case you forget! This allows you to make the most of the sun, without burning to a crisp!</p>
                    <h3>Logo Stickers and Neoprene Koozies</h3>
                    <p>Our party cruise includes some fun logo stickers and high-quality koozies for you to use on this trip and even take home with you. The koozies are great at keeping your drinks cool and refreshing in the hot sun!</p>
                    <h3>Premium Sound System</h3>
                    <p>Our impressive party cruise boats have a top-of-the-line sound system – because let’s be honest – no party cruise is complete without some great music to vibe to along the way. Boat parties in Florida are known for their music echoing across the water and this way you can choose whatever type of music that you are into. We have playlists already created with popular party music, but our sound system also can play playlists and music that you have on your devices – the choice is yours!</p>
                    <hr />
                    <h2>What is NOT Included on Our Party Boat Charter</h2>
                    <p>These include items you'll need to bring to your party boat charter trip.</p>
                    <h3>Alcohol</h3>
                    <p>While we would love to serve you drinks, it’s incredibly difficult to get this licence for a charter boat in Tampa. We’re working on it! We do not provide drinks, but you can bring your own on board – in fact, we encourage this! From experience, we find that it seems to run out faster on a party cruise than you would expect, so bring about double what you expect to drink. If you don’t drink it, you can just take it home, but it saves you from running out halfway through your party cruise. We have more than enough cooler space to keep them cold and out of the sun throughout the day.</p>
                    <h3>Full Meals</h3>
                    <p>While we do provide dry snacks as mentioned above, you should also bring on board any full meals that you plan on eating. If you would like us to do this for you, lunch with our photographer add-on can be included at an additional cost. Alternatively, bring your own food and add on a bottle girl or model to serve your drinks for you with this add-on service.</p>
                    <hr />
                    <h2>Time to Get the Party Started!</h2>
                    <p>Now that you have a better idea of what to bring and what we already have for you, all you need to do now is jump on board and have fun! Be sure to bring any full meals or alcoholic drinks you may want for the day. Everything else we can take care of. See you on the next boat party in Tampa. Feel free to give us a call at <a href="tel:813-742-8178">(813) 742-8178</a> or <a href="/contact/">submit a form</a> on our website if you have any questions.</p>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default BlogPage